<script>
  import Swal from "sweetalert2";
  import Layout from "@/layouts/main.vue";
  import appConfig from "@/../app.config";
  import PageHeader from "@/components/page-header";
  import { callUrl } from '@/helpers/api-apolo';
  import EmptyTableResult from "@/components/empty-table-result";
  import { saError } from '@/helpers/global-functions';
  import { overlay } from "@/state/helpers";
  import * as helpers from '@/helpers/helper-functions';

  export default {
    page: {
      title: "Pessoas",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    data() {
      return {
        title: "Pessoas",
        items: [{
            text: "CRM",
            href: "/",
          },
          {
            text: "Pessoas",
            active: true,
          },
        ],
        query: '',
        data: [],
        page: 1,
        perPage: 10,
        unify: {
          delete: {},
          keep: {}
        }
      };
    },
    computed: {
      pages() {
        if (this.page === 1 && !this.data.length) {
          return [];
        }
        if (this.page === 1 && this.data.length < this.perPage) {
          return [1];
        }
        if (this.page === 1 && this.data.length === this.perPage) {
          return [1, 2];
        }
        if (this.page > 1 && (!this.data.length || this.data.length < this.perPage)) {
          return [this.page - 1, this.page];
        }
        return [this.page - 1, this.page, this.page + 1];
      }
    },
    watch: {
      page() {
        this.getAllData();
      },
      query(value) {
        if (value === '' || value.length === 0) {
          this.getAllData(true)
        }
      }
    },
    methods: {
      ...overlay,
      ...helpers,
      search() {
        if (this.page !== 1) {
          this.page = 1;
        } else {
          this.getAllData();
        }
      },
      reset () {
        this.query = ''
        this.getAllData()
      },
      async getAllData() {
        try {
          this.changeShowOverlay({show: true});
          this.data = [];
          const params = [`page=${this.page}`, `limit=${this.perPage}`];
          if (this.query.length > 0) {
            params.push(`query=${this.query}`);
          }
          const dataM = await callUrl({}, '/person?' + params.join('&'), 'get')
          if (dataM && dataM.length) {
            this.data = dataM;
          }
        } catch (error) {
          console.warning(error.message);
        } finally {
          this.changeShowOverlay({show: false});
        }
      },
      addPersonToUnify(person) {
        if (!this.unify.delete || !Object.keys(this.unify.delete).length) {
          this.unify.delete = person;
          Swal.fire("Pessoa selecionada", "Selecione uma segunda pessoa que você queira unificar", "success");
          return;
        }
        this.unify.keep = person;
        Swal.fire({
          title: `Você esta prestes a unificar o(a) ${this.unify.delete.name} com o(a) ${this.unify.keep.name}`,
          text: "Após esta ação, não será possível reverter!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, unificar!",
          reverseButtons: true,
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
          customClass: {
            actions: 'd-flex justify-content-center',
            confirmButton: 'btn btn-success flex-grow-1',
            cancelButton: 'btn btn-outline-dark flex-grow-1'
          }
        }).then(async (result) => {
          if (result.value) {
            this.unifyPeople();
            return;
          }
          this.unify.delete = {};
          this.unify.keep   = {};
        });
      },
      async unifyPeople() {
        try {
          this.changeShowOverlay({show: true});
          await callUrl({}, `/person/${this.unify.delete.id}/replace-with/${this.unify.keep.id}`, 'delete');
          this.changeShowOverlay({show: false});
          Swal.fire("Sucesso!", "Pessoas unificadas", "success");
          this.data = this.data.filter(person => person.id !== this.unify.delete.id);
          this.unify.delete = {};
          this.unify.keep   = {};
        } catch (error) {
          this.changeShowOverlay({show: false});
          saError(error.message);
        }
      }
    },
    mounted() {
      this.getAllData();
    },
    components: {
      Layout,
      PageHeader,
      EmptyTableResult
    },
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <div>
          <div class="card">
            <div class="card-body bg-soft-light border border-dashed border-start-0 border-end-0">
              <form>
                <div class="row g-3">
                  <div class="col-xl-5 col-lg-5 col-md-6 col-sm-6 col-7">
                    <div class="search-box">
                      <input type="text" class="form-control search" v-model="query" placeholder="Busca por algum termo..." @keydown.enter.prevent="search()" >
                      <i class="ri-search-line search-icon"></i>
                      <i class=" ri-close-line search-icon" style="right: 7px !important; left: unset !important; cursor: pointer !important;" v-show="query.length > 0" @click="reset()"></i>
                    </div>
                    <div class="form-text" v-show="query.length > 0">Aperte ENTER para buscar.</div>
                  </div>
                  <div class="col-xl-5 col-lg-5 col-md-4 col-sm-3 col-1"></div>
                  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-4">
                    <button type="button" class="btn btn-primary w-100" @click="search()">
                      <i class="mdi mdi-magnify me-1 align-bottom"></i> Buscar
                    </button>
                  </div>
                </div>
              </form>
            </div>
            
            <div class="card-body">
              <div class="tab-content text-muted">
                <div class="tab-pane active" id="productnav-all" role="tabpanel">
                  <div id="table-product-list-all" class="table-card gridjs-border-none table-responsive">
                    <table class="table align-middle table-nowrap table-striped mb-0" id="customerTable">
                      <thead class="table-primary">
                        <tr class="text">
                          <th>Nome</th>
                          <th>Email</th>
                          <th>Telefone</th>
                          <th>Criado em</th>
                          <th>Atualizado em</th>
                          <th scope="col">Ações</th>
                        </tr>
                      </thead>
                      <tbody class="list form-check-all">
                        <tr class="gridjs-tr" v-for="(item, index) of data" :key="index">
                          <td data-column-id="product" class="gridjs-td">
                            <span>
                              <div class="d-flex align-items-center">
                                <div class="flex-grow-1">{{ item.name }}</div>
                              </div>
                            </span>
                          </td>
                          <td data-column-id="price" class="gridjs-td">{{ item.email }}</td>
                          <td data-column-id="price" class="gridjs-td">{{ item.telephone }}</td>
                          <td data-column-id="published" class="gridjs-td">
                            {{ translateDateOnly(item.created_at) }} <small class="text-muted">{{ translateTimeOnly(item.created_at) }}</small>
                          </td>
                          <td data-column-id="published" class="gridjs-td">
                            {{ translateDateOnly(item.updated_at) }} <small class="text-muted">{{ translateTimeOnly(item.updated_at) }}</small>
                          </td>
                          <td data-column-id="action" class="gridjs-td">
                            <span>
                              <div class="dropdown">
                                <button class="btn btn-soft-secondary btn-sm dropdown" type="button"
                                  data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="ri-more-fill"></i>
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end">
                                  <li>
                                    <router-link class="dropdown-item" :to="{ name: 'people-edit', params: { id: item.id } }">
                                      <i class="ri-pencil-fill align-bottom me-2 text-muted"></i> Editar
                                    </router-link>
                                  </li>
                                  <li>
                                    <a href="#" class="dropdown-item" @click.prevent="addPersonToUnify(item)">
                                      <i class="ri-group-line align-bottom me-2 text-muted"></i> Unificar
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <EmptyTableResult v-show="data.length == 0" />

                    <div class="d-flex justify-content-end m-3">
                      <div class="pagination-wrap hstack gap-2">
                        <a class="page-item pagination-prev" href="#" @click="--page" v-if="page > 1">Anterior</a>
                        <a class="page-item pagination-prev disabled" href="#" v-if="data.length && page == 1" @click.prevent="">Anterior</a>
                        <ul class="pagination listjs-pagination mb-0">
                          <li v-for="pageNumber in pages" :key="'page' + pageNumber" @click="page = pageNumber"
                            :class="{active: pageNumber == page, disabled: pageNumber != page}">
                            <a class="page" href="#">{{ pageNumber }}</a>
                          </li>
                        </ul>
                        <a class="page-item pagination-next" href="#" @click="++page" v-if="data.length == perPage">Próxima</a>
                        <a class="page-item pagination-next disabled" href="#" @click.prevent="" v-if="data.length && data.length < perPage">Próxima</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
